<template>
	<v-row no-gutters dense class="d-flex mb-8 flex-grow-0">
		<v-col cols="12" class="pa-0">
			<span v-on:click="$router.back()">
				<app-back-btn />
			</span>
			<span class="ml-4">
				<b class="heading-sf16" style="color:#7687A2">{{ getPath }}</b>
			</span>
		</v-col>
	</v-row>
</template>

<script>
export default {
	computed: {
		getPath() {
			let path = this.$router.currentRoute.fullPath;
			let new_path = path.split("/");
			return new_path[1] + " / " + new_path[2];
		}
	}
};
</script>